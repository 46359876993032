import { DELIMITER, MAX_LENGTH_EMPLID, SORT_DIRECTION } from "./constants";
import { asyncOptions, debouncedOptions, synchronousOptions } from "./enums";
import { isEmpty as lodashIsEmpty } from "lodash";
import moment from "moment";
import { format, isValid } from "date-fns";

export function buildAction(type, payload) {
    return { type, payload };
}

export const formatDate = (dateString, format = "LLL") => {
    if(lodashIsEmpty(dateString)) return "";
    const date = moment(dateString);
    if(!date.isValid()) return "";
    return date.format(format);
};

export function omitReduxProps(props = {}) {
    const clonedObject = Object.assign({}, props)
    delete clonedObject.history
    delete clonedObject.location
    delete clonedObject.match
    delete clonedObject.staticContext
    delete clonedObject.dispatch

    return clonedObject
}

export function isUnauthenticated(error) {
    const name = error?.name;
    const status = (error?.response && error?.response?.status) ? error.response.status : null;
    const unauthorizedErrors = ["The user is not authenticated", "No current user", "Request failed with status code 401"];
    const unauthorizedErrorNames = ["UserUnAuthenticatedException"];
    
    return (unauthorizedErrorNames.includes(name) || unauthorizedErrors.includes(error) || status === 401);
}

export const isValidEmplidLength = (emplid) => {
    return (emplid && (emplid.length >= (MAX_LENGTH_EMPLID - 1)));
};

export const isAsyncDropdownSubtype = (subtype = "") => {
    return subtype && (asyncOptions.includes(subtype) || debouncedOptions.includes(subtype))
}

export const isSyncDropdownSubtype = (subtype = "") => {
    const synchronousOptionKeys = Object.keys(synchronousOptions)
    return subtype && synchronousOptionKeys.includes(subtype)
}

export const getSyncOptions  = (subtype = "") => {
    return subtype && synchronousOptions[subtype]
}

export function getDisplayName(WrappedComponent) {
return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export function isEmpty(obj = {}) {
return typeof obj === 'object' ? Object.keys(obj).length === 0 : !!obj;
}

export function isObject(item) {
return (item && typeof item === 'object' && !Array.isArray(item));
}

export function deepCopy(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();
  
    if (isObject(target) && isObject(source)) {    
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) { 
            Object.assign(target, { [key]: {} });
          }else{          
  
            target[key] = Object.assign({}, target[key])
          }
          deepCopy(target[key], source[key]);
        } else {
          let keyValue = source[key]
          if(Array.isArray(source[key])) {
            keyValue = JSON.parse(JSON.stringify(source[key]))
          }
          Object.assign(target, { [key]: keyValue });
        }
      }
    } 
  
    return deepCopy(target, ...sources);
  }
export const getSortComparator = (sortBy, sortDirection) => {
    const descendingComparator = (a, b, sortBy) => {
        if(b[sortBy] < a[sortBy]) return -1;
        if(b[sortBy] > a[sortBy]) return 1;
        return 0;
    };
    return (sortDirection === SORT_DIRECTION.DESCENDING ? 
        (a, b) => descendingComparator(a, b, sortBy) : 
        (a, b) => -descendingComparator(a, b, sortBy)
    );
};

export const sort = (rows, comparator) => {
    const sortedRows = rows.map((row, index) => [row, index]);
    sortedRows.sort((a, b) => {
        const result = comparator(a[0], b[0]);
        if(result !== 0) return result;
        return (a[1] - b[1]);
    });
    return sortedRows.map((row) => row[0]);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatValue(value) {
  let val = value ?? "";
  if (isValid(new Date(val))) {
    val = format(new Date(val), "MMMM dd yyyy h:mma");
  } if(val.toUpperCase() === val) {
    val = capitalizeFirstLetter(val.toLowerCase())
  }

  return val
}

export const createId = (prefix) => `${prefix}-${Date.now().toString(36) + Math.random().toString(36).substr(2)}`

export const chunk = (array, count) => {
  const chunkSize = count;
  let newArr = []
  for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      newArr.push(chunk)
  }
  return newArr
}

export const generateUrlSeachObj = (urlSearch) => {
  return urlSearch.slice(1).split('&').reduce((currentValue, nextValue) => {
    let keyValueArr = nextValue.split('=')
    currentValue[keyValueArr[0]] = keyValueArr[1];
    return currentValue;
  }, {})
}

export const getProgram = (academicPlan) => {
  return (academicPlan ? academicPlan.split(DELIMITER.ACADEMIC_PLAN)[0] : null);
};