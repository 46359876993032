import { BOOL, CATEGORY_P } from "../../util/constants";
import { dropdownSubtypes, loggedinUserData, inputMappings } from "../../util/enums";
// import { createCommitteeSection } from "./util/shared";

export const formSections = [
  {
    title: "Graduation Information",
    id: "graduation-information",
    questions: [
      {
        title: "Graduation Term",
        id: "graduation-information-term",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        dataField: 'termCode',
        filter: {
          showFromPresent: 2
        }
      },
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduationLevel, 
        branch: "graduation-information-plan",
        dataField: 'gradLevel',
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        filter: [
          {
            "field":  "graduation-information-level",
            "match": "gradLevel",
            operation: "includes"
          }
        ],
        branch: "committee-advisor",
        results: [{
          label: 'Degree',
          field: "degree",
          dataField: 'gradDegree'
        }],
        dependencyHelperText: "Please select a level to select the appropriate plan.",
        dependencies: ["graduation-information-level"],
        dataField: 'academicPlan'
      },
      {
        //Summary to always show, clean up text at some point
        title: "End of Semester Option",
        id: "end-of-semester",
        summary:
          "The end of semester option means that you will not be graduating in the current semester. The EOS option is to be used only if all degree requirements are to be completed by the posted end of semester deadline with the degree being conferred at the commencement ceremony of the following semester. Do not utilize this option if you are trying to graduate using the regular semester/summer term graduation deadlines.",
        type: "checkbox",
        dataField: 'endOfSemesterOption',
        defaultValue: BOOL.NO,
        required: true
      },
    ],
  },
  {
    "id": "commencement",
    title: "Commencement",
    questions: [
      {
        id: "commencement-pick-up",
        type: inputMappings.radio,
        title: "How will you be obtaining your diploma?",
        required: true,
        dataField: 'commencement',
        choices: [
          {
            id: "commencement-will-attend",
            title: "I will attend commencement",
            value: "ATTEND"
          },
          {
            id: "commencement-one-week",
            title:
              "I will pick up my diploma within one week after commencement.",
            value: "PICKUP"
          },
          {
            id: "commencement-authorized-pickup",
            title: "I am authorizing someone else to pick up my diploma ",
            value: "AUTHORIZED_PICKUP",
            branch: "commencement-authorized-pickup-persons",
          },
          {
            id: "commencement-authorized-pickup-persons",
            title: "Authorized Persons",
            dataField: 'authorizedPickup',
            type: "inputGroup",
            numberOfResponsesMaximum: 2,
            questions: [
              {
                id: "commencement-authorized-pickup-first-name",
                required: true,
                title: "First Name",
                type: "textInput",
                dataField: 'firstName',
              },
              {
                id: "commencement-authorized-pickup-last-name",
                required: true,
                title: "Last Name",
                type: "textInput",
                dataField: 'lastName'
              }
            ],
            dependencies: [ "commencement-pick-up" ],
          },
          {
            id: "commencement-mail",
            title: "Please mail my diploma to the permanent address below",
            value: "MAIL",
            branch: "commencement-mailing-address",
          },
          {
            id: "commencement-mailing-address",
            title: "Address",
            type: loggedinUserData.mailingAddress,
            dataField: 'mailAddress',
            dependencies: [ "commencement-pick-up" ],
          }
        ],
      }
    ]
  },
  // createCommitteeSection()
  {
      title: "Committee",
      id: "committee",
      questions: [
        {
          id: "advisor-subheading",
          type: inputMappings.subheading,
          title: "Advisor Information"
        },
        {
          id: "committee-advisor",
          type: inputMappings.dropDown,
          dataField: "committee.advisor",
          title: "Advisor",
          summary: null,
          required: true,
          subtype: dropdownSubtypes.advisor,
          dependencies: ["graduation-information-plan"],
          dependencyHelperText: "Please select a plan to select the appropriate advisor."
        },
        {
          id: "co-advisor",
          type: inputMappings.dropDown,
          title: "Co-Advisor (if applicable)",
          subtype: dropdownSubtypes.coAdvisor,
          summary: null,
          required: false,
          dataField: "committee.coAdvisor"
        },
        {
          id: "committee-members",
          type: inputMappings.inputGroup,
          title: "Committee Members",
          subtitle: "Graduate Faculty committee members",
          summary: "(M)aster’s level faculty selection will require approval of the Graduate Studies Committee Chair.",
          required: true,
          numberOfResponsesMinimum: 1,
          numberOfResponsesMaximum: 4,
          dependencies: [],
          choices: [],
          dataField: "committee.members",
          questions: [
            {
              id: "committee-member-info",
              type: inputMappings.dropDown,
              subtype: dropdownSubtypes.facultyMember,
              title: "Faculty Member",
              summary: null,
              required: true
            }
          ]
        },
        {
          id: "external-members",
          type: inputMappings.inputGroup,
          title: "External Members",
          subtitle: "Non-Graduate Faculty committee members",
          summary: "For each external committee member, completion & submission of Committee and Examination Petition is required.",
          required: false,
          numberOfResponsesMinimum: 1,
          numberOfResponsesMaximum: 4,
          dependencies: [],
          choices: [],
          dataField: "committee.externalMembers",
          questions: [
            {
              id: "first-name",
              type: inputMappings.textInput,
              title: "First Name",
              dataField: 'firstName',
              summary: null,
              required: true,
              numberOfResponsesMinimum: 1,
              numberOfResponsesMaximum: 1,
              dependencies: [],
              choices: []
            },
            {
              id: "last-name",
              type: inputMappings.textInput,
              title: "Last Name",
              summary: null,
              required: true,
              dataField: 'lastName',
              numberOfResponsesMinimum: 1,
              numberOfResponsesMaximum: 1,
              dependencies: [],
              choices: []
            },
            {
              id: "email",
              type: inputMappings.email,
              title: "Email Address",
              dataField: "email",
              summary: null,
              required: true,
              numberOfResponsesMinimum: 1,
              numberOfResponsesMaximum: 1,
              dependencies: [],
              choices: []
            }
          ]
        }
      ]
    }
];

export const additionalFilters = [ {
  title: "Graduation Term",
  id: "termCode",
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.term,
  dataField: 'termCode',
  filter: {
    showFromPresent: 2
  }
},
{
  title: "Graduation Level",
  id: "level",
  required: true,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.graduationLevel, 
  dataField: 'gradLevel',
},
{
  title: "Graduation Plan",
  id: "plan",
  required: true,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.graduatePrograms,
  dataField: 'academicPlan'
},
{
  id: "eos-option",
  title: "End of Semester Option",
  required: false,
  type: inputMappings.dropDown,
  choices: [{
    id: "all-eos",
    title: "All",
    value: "All"
  },
  {
    id: "yes-eos",
    title: "Yes",
    value: "Yes"
  },
  {
    id: "no-eos",
    title: "No",
    value: "No"
  }
  ]
}];