import React, { Fragment, useCallback, useEffect, useMemo, useReducer } from "react";
import { dropdownSubtypes, inputMappings, loggedinUserData } from "../../../util/enums";
import Dropdown from "./Dropdown";
import InputGroup from "./InputGroup";
import RadioInput from "./RadioInput";
import Checkbox from "./Checkbox";
import Lookup from './Lookup'
import NameNLookup from './NameNLookup';
import TextInput from "./TextInput";
import FileUpload from "./FileUpload";
import { Mui } from "@osu/react-ui";
import DateTime from "./DateTime";
import Person from "../Person/containers";
import { updateInputGroupState } from "./util";
import { createId } from "../../../util/functions";
import { LOOKUP_NAME_BASE } from "./constants";

function InputMapper(props = {}) {
  const {
    className,
    dependencyHelperText,
    hidden,
    ...rest
  } = props;
  
  return <Fragment>
    {!!(hidden && dependencyHelperText) && <Mui.Alert className={className} severity="warning" variant="outlined">
        {dependencyHelperText}
      </Mui.Alert>}
    <MappedInput {...rest} className={hidden ? "display-none" : className} />
  </Fragment>
}

const MappedInput = (props = {}) =>{
  const {
    id,
    type,
    title,
    summary,
    summaryProps,
    required,
    choices,
    className: cls,
    value,
    onChange,
    dependencyHelperText,
    hidden,
    sectionState,
    numberOfResponsesMaximum,
    numberOfResponsesMinimum,
    lookupEmplid,
    setLookupEmplid,
    validation,
    Description,
    HelperText,
    ...rest
  } = props;
  const [inputGroupRows, setInputGroupRows] = useReducer(updateInputGroupState, [{ id: createId("default-id"), initial: true }]);
  const isInputGroup = type === inputMappings.inputGroup
  const inputGroupValue = (isInputGroup && Object.keys(value ?? {}).length) ? Object.values(value) : value

  let className = cls
  const onLookup = useCallback((value) => {
    let name = ""
    if(rest?.student) {
      name = value?.name
      if(!name) {
        name += value?.firstName || ""
        if(value?.lastName) name += ` ${value?.lastName}`
      }
    }
    
    if(name) {
      onChange(Object.assign({}, LOOKUP_NAME_BASE, {
        value: name
      }))
    }
    onChange({
      id,
      dataField: "osuid",
      value
    })
  }, [id, onChange, rest?.student])
  const onChangeCb = useCallback((value) => {
    onChange(value)
  }, [onChange])
  
  useEffect(() => {
    if(value?.emplid && type === inputMappings.lookup && setLookupEmplid) {
      setLookupEmplid(value.emplid)
    }
  }, [setLookupEmplid, value?.emplid, type])

  const defaultRow = inputGroupRows.length === 1 && inputGroupRows[0].initial 
  const valueExists = Array.isArray(inputGroupValue) && inputGroupValue.length
  const compareExistingToNew = JSON.stringify(inputGroupValue) === JSON.stringify(inputGroupRows) 
  
  const allowReset = useMemo(() => {
    return valueExists 
      && defaultRow 
      && isInputGroup
      && !compareExistingToNew
  }, [defaultRow, valueExists, isInputGroup, compareExistingToNew])

  useEffect(() => {
    if(allowReset) {
      setInputGroupRows({ type: "RESET", payload: inputGroupValue });
    }
  }, [allowReset, inputGroupValue])
  let helperText = rest?.helperText

  switch (type) {
    case loggedinUserData.mailingAddress:
    case loggedinUserData.cumUndergradGpa:
      return <Person
        type={type}
        value={value}
        id={id}
        className={className}
        onChange={onChange}
      />
    case inputMappings.phone:
    case inputMappings.street:
      return <Person
        type={type}
        emplid={lookupEmplid}
        className={className}
        id={id}
        value={value}
        required={required}
        onChange={onChangeCb}
        title={title}
        helperText={summary}
        {...rest}
      />
    case inputMappings.email:
    case inputMappings.numberInput:
    case inputMappings.textInput:
      if(type === inputMappings.numberInput) rest.type = "number";
      return (
        <TextInput
          id={id}
          value={value}
          className={className}
          required={required}
          onChange={onChange}
          title={title}
          description={summary}
          descriptionProps={summaryProps}
          {...rest}
        />
      );
    case inputMappings.inputGroup:
      return (
        <InputGroup
          id={id}
          required={required}
          numberOfResponsesMaximum={numberOfResponsesMaximum}
          numberOfResponsesMinimum={numberOfResponsesMinimum}
          onChange={({ type = "", payload = {} }) =>{
            setInputGroupRows(({
              type,
              payload: {
                ...payload,
                _options: {
                  onChangeHandler: (updatedValue) => {
                    onChange({
                      id,
                      dataField: rest?.dataField,
                      value: updatedValue,
                    })      
                  }
                }
              },
            }))
          }}
          label={title}
          summary={summary}
          value={inputGroupRows}
          className={className}
          {...rest}
        />
      );
    case inputMappings.dropDown:
      if(!helperText && ([
        dropdownSubtypes.advisor,
        dropdownSubtypes.coAdvisor,
        dropdownSubtypes.facultyMember,
        dropdownSubtypes.gradFacultyRepresentative,
        dropdownSubtypes.proposedAdvisor
      ].includes(rest?.subtype))) {
        helperText = "Please begin typing by last name to start your search"
      }

      return (
        <Dropdown
          className={className}
          choices={choices}
          id={id}
          value={value}
          label={title}
          required={required}
          sectionState={sectionState}
          onChange={(e) => {
            const value = e?.target?.value || e
            onChange({
              id,
              value,
            })
          }}
          {...rest}
          helperText={helperText}
        />
      );
    case inputMappings.radio:
      return (
        <RadioInput
          className={className}
          id={id}
          required={required}
          label={title}
          value={value}
          onChange={onChange}
          choices={choices}
          description={summary}
          sectionState={sectionState}
          {...rest}
        />
      );
    case inputMappings.checkbox:
      return <Checkbox
        className={className}
        value={value}
        onChange={(value) => {
          onChange({
            id,
            value
          })
        }}
        id={id}
        required={required}
        label={title}
        description={summary}
        choices={choices}
        {...rest}
      />
    case inputMappings.lookup:
      return <Lookup 
        id={id} 
        className={className}
        value={value}
        required={required}
        label={title}
        onChange={onLookup}
        student={rest?.student || false}
        facultyNomination={rest?.facultyNomination || false}
        HelperText={HelperText}
      />
    case inputMappings.nameNLookup:
      return <NameNLookup
        id={id} 
        className={className}
        value={value}
        required={required}
        label={title}
        description={summary}
        descriptionProps={summaryProps}
        onChange={onLookup}
      />
    case inputMappings.fileUpload:
      return <FileUpload
        id={id}
        onChange={onChange}
        className={className}
        value={value}
        label={title}
        required={required}
        Description={Description}
        HelperText={HelperText}
        {...rest}
      />
    case inputMappings.date:
    case inputMappings.time:
    case inputMappings.datetime:
      return <DateTime 
        className={className} 
        id={id} 
        label={title} 
        helperText={summary} 
        required={required} 
        value={value} 
        type={type}
        validation={validation}
        {...rest} 
        onChange={onChangeCb}  />
    default:
      console.error("Unexpected input type provided: ", type);
      return <div />
  }
}


InputMapper.defaultProps = {
  choices: []
}


export default InputMapper;
